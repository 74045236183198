import * as Ducks from "./actions";

const { Type, ...Action } = Ducks;

export { Type, Action };

export default (state = { open: false }, action = {}) => {
  switch (action.type) {
    case Type.OPEN_EDITOR:
      return { open: true, onConfirm: action.onConfirm, smiles: action.smiles, position: action.position };

    case Type.CLOSE_EDITOR:
      return { open: false };

    default:
      return state;
  }
};
