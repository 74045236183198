import * as Ducks from "./actions";
import * as Api from "./api"; 
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action, Api };

export default (state = { items: [], current: null, databases: null }, action = {}) => {
  switch (action.type) {
    case Type.REMOVE:
      return { ...state, ...action.data };
    case Type.CLEAR:
      return { ...state, items: [], current: null };
    case Type.UPDATE_CURRENT:
      return  { ...state, current: action.data };
    case Type.SET_ITEMS:
      return { ...state, items: action.data, current: 0 };
    case FaerunType.SET_DATA:
      return { ...state, items: [], current: null };
    case Type.SET_DATABASES:
        return { ...state, databases: action.data };
    default:
      return state;
  }
};
