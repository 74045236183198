import * as Ducks from "./actions";
import { Type as FaerunType } from "../faerun";

const { Type, ...Action } = Ducks;

export { Type, Action };

export default (state = { items: [], current: null, log: [], logItems: [], report: null }, action = {}) => {
  switch (action.type) {
    case Type.ADD_TO_CART:
      return { ...state, items: [...state.items, ...action.data], report: null };
    case Type.REMOVE_FROM_CART:
      return { ...state, ...action.data, report: null };
    case Type.CLEAR_CART:
      return { items: [], current: null, log: [], logItems: [], report: null  };
    case Type.UPDATE_CURRENT:
      return  { ...state, current: action.data };
    case Type.SET_ITEMS:
      return { ...state, items: action.data };
    case FaerunType.SET_DATA:
      return { items: [], current: null, log: [], logItems: [], report: null  };
    case Type.UPDATE_LOG:
      return { ...state, ...action.data };
    case Type.SET_LOG_FILTER:
      return { ...state, ...action.data };
    case Type.SET_REPORT:
      return { ...state, report: action.data };

    default:
      return state;
  }
};
